import moment from "moment";
import { generateUuid } from "./uuid";
import { getPatientOfficialHumanName, getPatientIdentifier } from "./patients";
import store from "../store";
import {
  listGoogleCalendarEvent,
  createZoomMeeting,
} from "./asyncServerRequests";
import { ALLERGY_RXCUI, ALLERGY_CODETYPE } from "../consts";

export const parseFhirBundle = bundle => {
  let resources = [];
  for (let i = 0; i < bundle.entry.length; i++) {
    resources.push(bundle.entry[i].resource);
  }
  return resources;
};

export const createScheduleResource = practitioner => {
  let slot = {
    resourceType: "Schedule",
    id: generateUuid(),
    text: {
      status: "generated",
      div: `<div xmlns="http://www.w3.org/1999/xhtml">\nSchedule for ${practitioner.name[0].text}\n</div>`,
    },
    actor: [
      {
        reference: `Practitioner/${practitioner.id}`,
      },
    ],
  };
  return slot;
};

export const createSlotResource = (
  id,
  startDateTime,
  endDateTime,
  status,
  scheduleId,
  comments
) => {
  if (!(startDateTime && endDateTime && status && scheduleId)) {
    throw {
      code: 500,
      error: "Missing required slot data",
    };
  }
  let startTimeInstant = startDateTime;
  let endTimeInstant = endDateTime;
  let slot = {
    resourceType: "Slot",
    id: id,
    text: {
      status: "generated",
      div: `<div xmlns="http://www.w3.org/1999/xhtml">\n${startDateTime} - ${endDateTime}: <b>${status}</b>\n</div>`,
    },
    schedule: {
      reference: `Schedule/${scheduleId}`,
    },
    status: status,
    start: startTimeInstant,
    end: endTimeInstant,
    comment: comments,
  };
  return slot;
};

export const createAppointmentResource = (
  startDateTime,
  endDateTime,
  status,
  description,
  slotId,
  practitionerId,
  patient,
  appointmentId
) => {
  if (appointmentId == null) {
    appointmentId = generateUuid();
  }
  let appointment = {
    resourceType: "Appointment",
    id: appointmentId,
    text: {
      status: "generated",
      div: `<div xmlns="http://www.w3.org/1999/xhtml">\n${startDateTime} - ${endDateTime}: <b>${status}</b>\n ${description}\n</div>`,
    },
    status: status,
    description: description,
    start: startDateTime,
    end: endDateTime,
    slot: [
      {
        reference: `Slot/${slotId}`,
      },
    ],
    participant: [
      {
        actor: {
          reference: `Patient/${patient.id}`,
          display: parseHumanName(patient.name),
        },
        status: "accepted",
      },
      {
        actor: {
          reference: `Practitioner/${practitionerId}`,
        },
        status: "accepted",
      },
    ],
  };
  return appointment;
};

export const createCompositionResource = (note, patient, user) => {
  let author = null;
  let section = [];
  if (/Practitioner/gi.test(user.roles) && user.practitionerId) {
    author = {
      reference: `Practitioner/${user.practitionerId}`,
      display: user.firstName + " " + user.lastName,
    };
  }
  note.noteFields.forEach(function(noteField) {
    section.push({
      title: noteField.label,
      text: {
        status: "generated",
        div: noteField.text,
      },
      extension: [
        {
          url:
            process.env.REACT_APP_FHIR_ENDPOINT +
            "/fhir/StructureDefinition/composition-section-openNote",
          valueBoolean: noteField.open,
        },
      ],
    });
  });

  let followUpExtension = {
    url:
      process.env.REACT_APP_FHIR_ENDPOINT +
      "/fhir/StructureDefinition/composition-followUp",
    extension: [
      {
        url: "followUp",
        valueBoolean: note.followUp,
      },
    ],
  };

  if (note.followUp) {
    followUpExtension.extension.push(
      {
        url: "followUpCount",
        valueInteger: note.followUpCount,
      },
      {
        url: "followUpDate",
        valueDateTime: note.followUpDate,
      },
      {
        url: "followUpFrequency",
        valueString: note.followUpFrequency,
      },
      {
        url: "followUpMessage",
        valueString: note.followUpMessage,
      }
    );
  }
  let composition = {
    resourceType: "Composition",
    id: note.id,
    text: {
      status: "generated",
      div: `<div xmlns="http://www.w3.org/1999/xhtml">\n<p>${
        note.type.display
      } - ${parseHumanName(patient.name)}</p></div>`,
    },
    status: note.signed ? "final" : "preliminary",
    type: {
      coding: [
        note.type,
        {
          system: "template-uuid",
          code: note.template.value._id,
          display: note.template.value.title,
        },
      ],
      text: note.type.display,
    },
    subject: {
      reference: `Patient/${patient.id}`,
      display: parseHumanName(patient.name),
    },
    date: moment()
      .utc()
      .format(),
    event: [
      {
        period: {
          start: note.eventDate,
        },
      },
    ],
    author: [author],
    title: note.type.display,
    custodian: {
      reference: `Organization/${user.organizationId}`,
    },
    section: section,
    extension: [followUpExtension],
  };
  return composition;
};

export const compositionToNoteForm = composition => {
  let note = {};
  note["id"] = composition.id;
  let type = composition.type.coding.find(element => {
    return element.system === "http://loinc.org";
  });
  if (!type) {
    note["type"] = { display: composition.type.text };
  } else {
    note["type"] = type;
  }
  let template = composition.type.coding.find(element => {
    return element.system === "template-uuid";
  });
  note["template"] = {
    value: { _id: template.code, title: template.display },
    label: template.display,
  };
  note["eventDate"] =
    composition.event &&
    composition.event[0].period &&
    composition.event[0].period.start;
  note["date"] = composition.date;
  note["noteFields"] = [];
  composition.section.forEach(section => {
    note["noteFields"].push({
      label: section.title,
      open: section.extension[0].valueBoolean,
      text: section.text.div,
    });
  });
  if (composition.extension && composition.extension[0].extension) {
    let followUp = composition.extension[0].extension.find(element => {
      return element.url === "followUp";
    });
    note["followUp"] =
      followUp && followUp.valueBoolean ? followUp.valueBoolean : false;
    let followUpCount = composition.extension[0].extension.find(element => {
      return element.url === "followUpCount";
    });
    note["followUpCount"] =
      followUpCount && followUpCount.valueInteger
        ? followUpCount.valueInteger
        : null;
    let followUpDate = composition.extension[0].extension.find(element => {
      return element.url === "followUpDate";
    });
    note["followUpDate"] =
      followUpDate && followUpDate.valueDateTime
        ? followUpDate.valueDateTime
        : null;
    let followUpFrequency = composition.extension[0].extension.find(element => {
      return element.url === "followUpFrequency";
    });
    note["followUpFrequency"] =
      followUpFrequency && followUpFrequency.valueString
        ? followUpFrequency.valueString
        : null;
    let followUpMessage = composition.extension[0].extension.find(element => {
      return element.url === "followUpMessage";
    });
    note["followUpMessage"] =
      followUpMessage && followUpMessage.valueString
        ? followUpMessage.valueString
        : null;
  }
  note["signed"] = composition.status === "final" ? true : false;
  return note;
};

export const createGoalResource = (goal, patient, user) => {
  let goalResource = {
    resourceType: "Goal",
    id: goal.id,
    text: {
      status: "generated",
      div: `<div xmlns="http://www.w3.org/1999/xhtml">\n<p>${goal.summary}</p></div>`,
    },
    status: "in-progress",
    description: {
      text: goal.summary,
    },
    subject: {
      reference: `Patient/${patient.id}`,
      display: parseHumanName(patient.name),
    },
    startDate: goal.startDate,
    target: {
      measure: {
        text: goal.steps,
      },
    },
  };
  return goalResource;
};

export const goalResourceToForm = goalResource => {
  let goal = {};
  goal["id"] = goalResource.id;
  goal["summary"] = goalResource.description && goalResource.description.text;
  goal["steps"] =
    goalResource.target &&
    goalResource.target.measure &&
    goalResource.target.measure.text;
  goal["startDate"] = goalResource.startDate;
  return goal;
};

export const getGoalSummary = goalResource => {
  return goalResource.description && goalResource.description.text
    ? goalResource.description.text
    : "";
};

export const createConditionResource = (condition, patient, user) => {
  let conditionResource = {
    resourceType: "Condition",
    id: condition.id,
    text: {
      status: "generated",
      div: `<div xmlns="http://www.w3.org/1999/xhtml">\n<p>${condition.description}</p></div>`,
    },
    clinicalStatus: condition.status,
    code: {
      text: condition.description,
    },
    subject: {
      reference: `Patient/${patient.id}`,
      display: parseHumanName(patient.name),
    },
    onsetDateTime: condition.onsetDate,
    abatementDateTime: condition.resolvedDate,
    note: [
      {
        text: condition.notes,
      },
    ],
    bodySite: [
      {
        text: condition.bodySite,
      },
    ],
  };
  return conditionResource;
};

export const conditionResourceToForm = conditionResource => {
  let condition = {};
  condition["id"] = conditionResource.id;
  condition["description"] =
    conditionResource.code && conditionResource.code.text;
  condition["notes"] = conditionResource.note && conditionResource.note[0].text;
  condition["onsetDate"] = conditionResource.onsetDateTime;
  condition["resolvedDate"] = conditionResource.abatementDateTime;
  condition["status"] = conditionResource.clinicalStatus;
  condition["bodySite"] =
    conditionResource.bodySite && conditionResource.bodySite[0].text;
  return condition;
};

export const createFamilyHxResource = (familyHx, patient) => {
  let familyHxResource = {
    resourceType: "FamilyMemberHistory",
    id: familyHx.id,
    text: {
      status: "generated",
      div: `<div xmlns="http://www.w3.org/1999/xhtml">\n<p>${familyHx.condition} - ${familyHx.relationship}</p></div>`,
    },
    status: "completed",
    patient: {
      reference: `Patient/${patient.id}`,
      display: parseHumanName(patient.name),
    },
    relationship: {
      text: familyHx.relationship,
    },
    gender: familyHx.gender,
    condition: [
      {
        code: {
          text: familyHx.condition,
        },
        onsetAge: {
          value: familyHx.onsetAge,
          unit: "yr",
          system: "http://unitsofmeasure.org",
          code: "a",
        },
        note: [
          {
            text: familyHx.notes,
          },
        ],
      },
    ],
  };
  return familyHxResource;
};

export const familyHxResourceToForm = familyHxResource => {
  let familyHx = {};
  familyHx["id"] = familyHxResource.id;
  familyHx["condition"] =
    familyHxResource.condition &&
    familyHxResource.condition[0].code &&
    familyHxResource.condition[0].code.text;
  familyHx["notes"] =
    familyHxResource.condition &&
    familyHxResource.condition[0].note &&
    familyHxResource.condition[0].note[0].text;
  familyHx["onsetAge"] =
    familyHxResource.condition &&
    familyHxResource.condition[0].onsetAge &&
    familyHxResource.condition[0].onsetAge.value;
  familyHx["gender"] = familyHxResource.gender;
  familyHx["relationship"] =
    familyHxResource.relationship && familyHxResource.relationship.text;
  return familyHx;
};

export const createPractitionerResource = user => {
  let telecom = [
    {
      system: "email",
      value: user.email,
      use: "work",
    },
  ];
  if (user.phoneNumber) {
    telecom.push({
      system: "phone",
      value: user.phoneNumber,
      use: "work",
    });
  }
  const text =
    (user.prefix ? user.prefix + " " : "") +
    user.firstName +
    " " +
    user.lastName +
    (user.suffix ? ", " + user.suffix : "");
  let practitioner = {
    resourceType: "Practitioner",
    id: user.practitionerId,
    text: {
      status: "generated",
      div: `<div xmlns="http://www.w3.org/1999/xhtml">\n<p>${text}</p></div>`,
    },
    name: [
      {
        use: "official",
        text: text,
        family: user.lastName,
        given: [user.firstName],
        prefix: [user.prefix],
        suffix: [user.suffix],
      },
    ],
    active: /Practitioner/gi.test(user.roles),
    telecom: telecom,
    birthDate: user.dob,
  };
  return practitioner;
};

export const getAvailabilityForPractitionerFromUsers = (
  practitionerId,
  users
) => {
  let user = users.find(user => user.practitionerId === practitionerId);
  return user && user.availability;
};

export const getPractitionerValues = (practitionerResources, users) => {
  let practitioners = [];
  for (let i = 0; i < practitionerResources.length; i++) {
    let name = parseHumanName(practitionerResources[i].name);
    let availability = getAvailabilityForPractitionerFromUsers(
      practitionerResources[i].id,
      users
    );
    if (availability && availability.timezone) {
      practitioners.push({
        id: practitionerResources[i].id,
        name: name,
        timezone: availability.timezone,
      });
    }
  }
  return practitioners;
};

export const parseHumanName = humanNames => {
  let name = "";
  for (let i = 0; i < humanNames.length; i++) {
    if (humanNames[i].use === "official") {
      if (humanNames[i].text) {
        name = humanNames[i].text;
      } else {
        for (let j = 0; j < humanNames[i].given.length; j++) {
          name = name.concat(humanNames[i].given[j], " ");
        }
        name = name.concat(humanNames[i].family);
      }
    }
  }
  if (name === "") {
    if (humanNames[0].text) {
      name = humanNames[0].text;
    } else {
      for (let i = 0; i < humanNames[0].given.length; i++) {
        name = name.concat(humanNames[0].given[i], " ");
      }
      name = name.concat(humanNames[0].family);
    }
  }
  return name;
};

export const getOfficialName = humanNames => {
  let name = null;
  for (let i = 0; i < humanNames.length; i++) {
    if (humanNames[i].use === "official") {
      name = humanNames[i];
    }
  }
  if (name === null) {
    name = humanNames[0];
  }
  return name;
};

export const getCasualName = humanNames => {
  let name = "";
  for (let i = 0; i < humanNames.length; i++) {
    if (humanNames[i].use === "usual") {
      for (let j = 0; j < humanNames[i].given.length; j++) {
        name = name.concat(humanNames[i].given[j], " ");
      }
      name = name.concat(humanNames[i].family);
    }
  }
  if (name === "") {
    for (let i = 0; i < humanNames[0].given.length; i++) {
      name = name.concat(humanNames[0].given[i], " ");
    }
    name = name.concat(humanNames[0].family);
  }
  return name;
};

export const getPatientNameFromAppointment = appointment => {
  for (let i = 0; i < appointment.participant.length; i++) {
    let regex = /^#*Patient/i;
    if (regex.test(appointment.participant[i].actor.reference)) {
      if (appointment.participant[i].actor.display != null) {
        return appointment.participant[i].actor.display;
      }
    }
  }
  return null;
};

export const getPatientIdFromAppointment = appointment => {
  for (let i = 0; i < appointment.participant.length; i++) {
    let regex = /^#*Patient/i;
    if (regex.test(appointment.participant[i].actor.reference)) {
      let index = /\//.exec(appointment.participant[i].actor.reference);
      return appointment.participant[i].actor.reference.substring(
        index.index + 1
      );
    }
  }
  return null;
};

export const getSlotIdFromAppointment = appointment => {
  for (let i = 0; i < appointment.slot.length; i++) {
    let regex = /^#*Slot/i;
    if (regex.test(appointment.slot[i].reference)) {
      let index = /\//.exec(appointment.slot[i].reference);
      return appointment.slot[i].reference.substring(index.index + 1);
    }
  }
  return null;
};

export const createPatientResource = (patientData, organizationId) => {
  let telecoms = [];
  // eslint-disable-next-line no-loop-func
  if (patientData.phoneNumber) {
    telecoms.push({
      system: "phone",
      value: patientData.phoneNumber,
      use: patientData.phoneType,
      rank:
        patientData.phoneType === "mobile"
          ? 1
          : patientData.phoneType === "home"
          ? 2
          : 4,
    });
  }
  if (patientData.email) {
    telecoms.push({
      system: "email",
      value: patientData.email,
      rank: 1,
    });
  }
  let humanName = [];
  // patientData.nameFields.forEach(name => {
  // let name = {};
  // name.nameUse = "official";
  let given = [patientData.firstName];
  if (patientData.middleName) {
    given.push(patientData.middleName);
  }
  let text = `${given.join(" ")} ${patientData.lastName}`;
  text = patientData.prefix ? `${patientData.prefix} ` + text : text;
  humanName.push({
    use: "official",
    text: text,
    family: patientData.lastName,
    given: given,
    prefix: [patientData.prefix],
  });
  // });
  let addressLine = [patientData.addressLine1];
  if (patientData.addressLine2) {
    addressLine.push(patientData.addressLine2);
  }
  let patientResource = {
    resourceType: "Patient",
    id: generateUuid(),
    text: {
      status: "generated",
      div: `<div xmlns="http://www.w3.org/1999/xhtml">\n<p>${parseHumanName(
        humanName
      )}</p></div>`,
    },
    active: patientData.status,
    name: humanName,
    telecom: telecoms,
    gender: patientData.gender,
    birthDate: patientData.dob,
    address: [
      {
        line: addressLine,
        city: patientData.city,
        state: patientData.state,
        postalCode: patientData.zipcode,
        country: "US",
      },
    ],
    managingOrganization: {
      reference: `Organization/${organizationId}`,
    },
  };
  return patientResource;
};

export const convertPatientToFormFields = patientResource => {
  let id = patientResource.id;
  let nameFields = [];
  // must have official name first in array
  patientResource.name.forEach((humanName, index) => {
    if (humanName.use === "official") {
      nameFields.push({
        firstName: humanName.given[0],
        middleName: humanName.given[1],
        lastName: humanName.family,
        nameUse: humanName.use,
        prefix: humanName.prefix ? humanName.prefix[0] : null,
      });
    }
  });
  patientResource.name.forEach((humanName, index) => {
    if (humanName.use === "usual") {
      nameFields.push({
        firstName: humanName.given[0],
        middleName: humanName.given[1],
        lastName: humanName.family,
        nameUse: humanName.use,
        prefix: humanName.prefix ? humanName.prefix[0] : null,
      });
    }
  });
  let email;
  let phoneNumber;
  let phoneType;
  patientResource.telecom.forEach((contact, index) => {
    if (contact.system === "phone" && contact.use === "home") {
      phoneNumber = contact.value;
      phoneType = contact.use;
    }
    // check for mobile second so it overrides phone vars
    if (contact.system === "phone" && contact.use === "mobile") {
      phoneNumber = contact.value;
      phoneType = contact.use;
    }
    if (contact.system === "email") {
      email = contact.value;
    }
  });
  let status = patientResource.active ? "active" : "inactive";
  let dob = patientResource.birthDate ? patientResource.birthDate : null;
  let addressLine1;
  let addressLine2;
  let city;
  let state;
  let zipcode;
  patientResource.address.forEach((address, index) => {
    addressLine1 = address.line[0];
    addressLine2 = address.line[1];
    city = address.city;
    state = address.state;
    zipcode = address.postalCode;
  });
  let gender = patientResource.gender;
  let dosespotId = getPatientDosespotId(patientResource);
  return {
    id: id,
    nameFields: nameFields,
    email: email,
    phoneNumber: phoneNumber,
    phoneType: phoneType,
    status: status,
    dob: dob,
    addressLine1: addressLine1,
    addressLine2: addressLine2,
    city: city,
    state: state,
    zipcode: zipcode,
    gender: gender,
    prescribe: dosespotId ? true : false,
  };
};

export const checkHintPatientsWithDbPatients = (
  hintPatients,
  localPatients
) => {
  localPatients.forEach(patient => {
    if (
      Array.isArray(hintPatients.length) &&
      patient.identifier &&
      patient.identifier[0].system === "hint.com"
    ) {
      let index = hintPatients.findIndex(hint => {
        return hint.id === patient.identifier[0].value;
      });
      if (index >= 0) {
        hintPatients[index].integration_sync_status = "synced";
      }
    }
  });
  return hintPatients;
};

export const convertFhirToHintPatient = patientResource => {
  let address = {};
  if (
    Array.isArray(patientResource.address) &&
    patientResource.address.length
  ) {
    address["city"] = patientResource.address[0].city;
    address["country"] = patientResource.address[0].country;
    address["line1"] = patientResource.address[0].line
      ? patientResource.address[0].line[0]
      : null;
    address["line2"] = patientResource.address[0].line
      ? patientResource.address[0].line[1]
      : null;
    address["state"] = patientResource.address[0].state;
    address["zip"] = patientResource.address[0].postalCode;
  }
  let telecom = getTelecomInfo(patientResource.telecom);
  let officialName = getPatientOfficialHumanName(patientResource);
  let hintPractitionerId;
  let practitionerId =
    Array.isArray(patientResource.generalPractitioner) &&
    patientResource.generalPractitioner.length
      ? getPractitionerIdFromReference(patientResource.generalPractitioner[0])
      : null;
  if (practitionerId) {
    hintPractitionerId = getPractitionerIdentifier(practitionerId, "hint.com");
  }
  let hintId = getPatientIdentifier(patientResource, "hint.com");
  let hintPatient = {
    address_city: address ? address.city : null,
    address_country: address ? address.country : null,
    address_line1: address ? address.line1 : null,
    address_line2: address ? address.line2 : null,
    address_state: address ? address.state : null,
    address_zip: address ? address.zip : null,
    dob: patientResource.birthDate,
    email: telecom.email,
    first_name: officialName.given ? officialName.given[0] : null,
    last_name: officialName.given ? officialName.family : null,
    middle_name:
      officialName.given && officialName.given.length > 1
        ? officialName.given[1]
        : null,
    title: officialName.prefix ? officialName.prefix[0] : null,
    sex:
      patientResource.gender !== "male" && patientResource.gender !== "female"
        ? null
        : patientResource.gender,
    phones: telecom.phoneNumber
      ? [{ number: telecom.phoneNumber, type: telecom.phoneType }]
      : null,
    integration_record_id: patientResource.id,
    akuteId: patientResource.id,
    integration_web_link: `${process.env.REACT_APP_AKUTE_URL}/patient/dashboard?id=${patientResource.id}`,
    id: hintId,
    practitioner: hintPractitionerId
      ? {
          id: hintPractitionerId,
        }
      : null,
  };
  return hintPatient;
};

export const getPractitionerIdFromReference = reference => {
  let regex = /^#*Practitioner/i;
  if (regex.test(reference.reference)) {
    let index = /\//.exec(reference.reference);
    return reference.reference.substring(index.index + 1);
  }
  return null;
};

export const getPractitionerIdentifier = (practitionerId, system) => {
  const schedule = store.getState().schedule;
  let practitioners = schedule.practitioners;
  let practitionerResource = practitioners.find(practitioner => {
    return practitioner.id === practitionerId;
  });
  if (!practitionerResource.identifier) {
    return null;
  }
  let value = null;
  practitionerResource.identifier.forEach(identifier => {
    if (identifier.system === system) {
      value = identifier.value;
      return;
    }
  });
  return value;
};

export const getTelecomInfo = telecom => {
  let telecomResult = {
    phoneNumber: null,
    phoneType: null,
    email: null,
  };
  if (Array.isArray(telecom) && telecom.length > 0) {
    telecom.forEach(contact => {
      if (contact.system === "phone" && contact.use === "home") {
        telecomResult.phoneNumber = contact.value;
        telecomResult.phoneType = contact.use;
      }
      // check for mobile second so it overrides phone vars
      if (contact.system === "phone" && contact.use === "mobile") {
        telecomResult.phoneNumber = contact.value;
        telecomResult.phoneType = contact.use;
      }
      if (contact.system === "email" && contact.value) {
        telecomResult.email = contact.value.toLowerCase();
      }
    });
  }
  return telecomResult;
};

export const convertMedicationToFhir = (medication, patient) => {
  let medicationResource = {
    id: "med1",
    status: medication.status === "active" ? "active" : "inactive",
    ingredient: [
      {
        itemCodeableConcept: {
          coding: [
            {
              system: "http://www.nlm.nih.gov/research/umls/rxnorm",
              code: medication.rxcui,
              display: medication.scdc
                ? medication.scdc.label
                : medication.searchTerm,
            },
          ],
          text: medication.scdc ? medication.scdc.label : medication.searchTerm,
        },
        amount: {},
      },
    ],
  };

  let note = medication.notes ? [{ text: medication.notes }] : null;

  let medicationStatementResource = {
    resourceType: "MedicationStatement",
    id: generateUuid(),
    status: medication.status,
    medicationCodeableConcept: medicationResource,
    effectivePeriod: {
      start: medication.startDate,
      end: medication.endDate,
    },
    dateAsserted: moment()
      .utc()
      .format(),
    subject: {
      reference: `Patient/${patient.id}`,
    },
    dosage: [
      {
        text: medication.frequency.label,
        patientInstruction: medication.instructions,
        asNeededBoolean: medication.prn,
        doseQuantity: {
          value: medication.strength,
          unit: medication.units,
          code: medication.units,
          system: "http://unitsofmeasure.org",
        },
        timing: {
          code: {
            coding: [
              {
                display: medication.frequency.label,
                code: medication.frequency.value,
                system: "http://hl7.org/fhir/v3/GTSAbbreviation",
              },
            ],
            text: medication.frequency.label,
          },
        },
      },
    ],
    note: note,
  };
  return medicationStatementResource;
};

export const getNumTasksDue = tasks => {
  let urgentTasksNum = 0;
  let dueTasksNum = 0;
  if (!tasks) {
    return { urgentTasksNum: 0, dueTasksNum: 0 };
  }
  tasks.forEach(task => {
    if (task.status !== "complete")
      if (task.priority === "p1") {
        urgentTasksNum++;
      } else if (moment(task.dueDate).isSameOrBefore(moment())) {
        dueTasksNum++;
      }
  });
  return { urgentTasksNum: urgentTasksNum, dueTasksNum: dueTasksNum };
};

export const getPatientDosespotId = patient => {
  let dosespotId = null;
  if (patient.identifier) {
    patient.identifier.forEach(identifier => {
      if (identifier.system === "dosespot.com") {
        dosespotId = identifier.value;
      }
    });
  }
  return dosespotId;
};

export const findExactNameMatch = (patientResources, firstName, lastName) => {
  let activePatientArray = [];
  let inactivePatientArray = [];
  let match = false;
  patientResources.forEach(patient => {
    let name = getOfficialName(patient.name);
    name.given.forEach(givenName => {
      if (givenName.trim().toLowerCase() === firstName.trim().toLowerCase()) {
        match = true;
      }
    });
    if (name.family.trim().toLowerCase() === lastName.trim().toLowerCase()) {
      match = match & true;
    } else {
      match = false;
    }
    if (match) {
      if (patient.active) {
        activePatientArray.push(patient);
      } else {
        inactivePatientArray.push(patient);
      }
    }
  });
  return {
    activePatientResources: activePatientArray,
    inactivePatientResources: inactivePatientArray,
  };
};

export const getPractitionerEmail = practitionerResource => {
  let email = null;
  practitionerResource.telecom.forEach(telecom => {
    if (telecom.system === "email") {
      email = telecom.value;
    }
    return;
  });
  return email;
};

export const createQuestionnaireResponseResource = (
  patient,
  questionnaire,
  answers,
  oldQuestionnaireResponse
) => {
  if (!patient || !questionnaire || !answers) {
    return null;
  }
  if (!Array.isArray(questionnaire.item) || questionnaire.item.length === 0) {
    return null;
  }
  let id;
  if (oldQuestionnaireResponse) {
    id = oldQuestionnaireResponse.id;
  } else {
    id = generateUuid();
  }
  let questionnaireResponseResource = {
    id: id,
    resourceType: "QuestionnaireResponse",
    questionnaire: {
      reference: `Questionnaire/${questionnaire.id}`,
      display: questionnaire.title,
      extension: [
        {
          url:
            "https://akutehealth.com/fhir/StructureDefinition/questionnaire-description",
          valueString: questionnaire.description,
        },
      ],
    },
    status: "completed",
    authored: moment()
      .utc()
      .format(),
    subject: {
      reference: `Patient/${patient.id}`,
    },
    item: [],
  };
  questionnaire.item.forEach(item => {
    let linkId = item.linkId;
    let text = item.text;
    let itemObject = {
      linkId: linkId,
      text: text,
      definition: item.definition,
    };
    if (
      item.type === "group" &&
      Array.isArray(item.item) &&
      item.item.length > 0
    ) {
      itemObject["item"] = [];
      item.item.forEach(subItem => {
        let subItemInfo = {};
        subItemInfo["linkId"] = subItem.linkId;
        subItemInfo["text"] = subItem.text;
        let subItemAnswer = item.repeats
          ? getAnswerForItem(subItem, answers[linkId])
          : getAnswerForItem(subItem, answers);
        if (Array.isArray(subItemAnswer) && subItemAnswer.length > 0) {
          subItemInfo["answer"] = subItemAnswer;
        }
        itemObject.item.push(subItemInfo);
      });
    } else if (item.type !== "display" && item.type !== "group") {
      // let itemAnswer = getAnswerForItem(item, answers);
      let itemAnswer = item.repeats
        ? getAnswerForItem(item, answers[linkId])
        : getAnswerForItem(item, answers);
      if (Array.isArray(itemAnswer) && itemAnswer.length > 0) {
        itemObject["answer"] = itemAnswer;
      }
    }
    questionnaireResponseResource.item.push(itemObject);
  });
  return questionnaireResponseResource;
};

const getAnswerForItem = (item, answers) => {
  let answer = [];
  // console.debug("answers", answers);
  // console.debug("item", item);
  switch (item.type) {
    case "string": {
      if (!answers) {
        answer.push({ valueString: "" });
      } else if (Array.isArray(answers) && answers.length > 0) {
        answers.forEach(element => {
          // if (element[item.linkId]) {
          answer.push({ valueString: element[item.linkId] });
          // }
        });
      } else {
        // if (answers[item.linkId]) {
        answer.push({ valueString: answers[item.linkId] });
        // }
      }
      break;
    }
    case "text": {
      if (!answers) {
        answer.push({ valueString: "" });
      } else if (Array.isArray(answers) && answers.length > 0) {
        answers.forEach(element => {
          // if (element[item.linkId]) {
          answer.push({ valueString: element[item.linkId] });
          // }
        });
      } else {
        // if (answers[item.linkId]) {
        answer.push({ valueString: answers[item.linkId] });
        // }
      }
      break;
    }
    case "date": {
      if (!answers) {
        answer.push({ valueDate: null });
      } else if (Array.isArray(answers) && answers.length > 0) {
        answers.forEach(element => {
          // if (element[item.linkId]) {
          answer.push({ valueDate: element[item.linkId] });
          // }
        });
      } else {
        // if (answers[item.linkId]) {
        answer.push({ valueDate: answers[item.linkId] });
        // }
      }
      break;
    }
    case "dateTime": {
      if (!answers) {
        answer.push({ valueDateTime: null });
      } else if (Array.isArray(answers) && answers.length > 0) {
        answers.forEach(element => {
          // if (element[item.linkId]) {
          answer.push({ valueDateTime: element[item.linkId] });
          // }
        });
      } else {
        // if (answers[item.linkId]) {
        answer.push({ valueDateTime: answers[item.linkId] });
        // }
      }
      break;
    }
    case "choice": {
      if (!answers) {
        answer.push({
          valueCoding: {
            code: null,
          },
        });
      } else if (Array.isArray(answers) && answers.length > 0) {
        answers.forEach(element => {
          if (element[item.linkId] && element[item.linkId].option) {
            answer.push(element[item.linkId].option);
          } else if (element[item.linkId] && element[item.linkId].value) {
            answer.push({
              valueCoding: {
                code: element[item.linkId].value,
                display: element[item.linkId].label,
                system: element[item.linkId].system,
              },
            });
          } else {
            answer.push({
              valueCoding: {
                code: element[item.linkId],
              },
            });
          }
          // }
        });
      } else {
        // console.debug("choice", answers, item.linkId);
        if (answers[item.linkId] && answers[item.linkId].option) {
          answer.push(answers[item.linkId].option);
        } else if (answers[item.linkId] && answers[item.linkId].value) {
          answer.push({
            valueCoding: {
              code: answers[item.linkId].value,
              display: answers[item.linkId].label,
              system: answers[item.linkId].system,
            },
          });
        } else {
          answer.push({
            valueCoding: {
              code: answers[item.linkId],
            },
          });
        }
        // }
      }
      break;
    }
    case "open-choice": {
      if (!answers) {
        answer.push({
          valueCoding: {
            code: null,
          },
        });
      } else if (Array.isArray(answers) && answers.length > 0) {
        answers.forEach(element => {
          const isAllergy = element.allergy;
          if (element[item.linkId] && element[item.linkId].value) {
            if (isAllergy && typeof element[item.linkId].value === "object") {
              answer.push({
                valueCoding: {
                  system: "dosespot.com",
                  display: element[item.linkId].label,
                  code: element[item.linkId].value.Code,
                  extension: [
                    {
                      valueCode: element[item.linkId].value.RxCUI,
                      url: ALLERGY_RXCUI,
                    },
                    {
                      valueCode: element[item.linkId].value.CodeType,
                      url: ALLERGY_CODETYPE,
                    },
                  ],
                },
              });
            } else {
              answer.push({
                valueCoding: {
                  code: element[item.linkId].value,
                  display: element[item.linkId].label,
                  system: element[item.linkId].system,
                },
              });
            }
          } else {
            answer.push({
              valueCoding: {
                code: element[item.linkId],
              },
            });
          }
          // }
        });
      } else {
        const isAllergy = answers.allergy;
        if (answers[item.linkId] && answers[item.linkId].value) {
          if (isAllergy && typeof answers[item.linkId].value === "object") {
            answer.push({
              valueCoding: {
                system: "dosespot.com",
                display: answers[item.linkId].label,
                code: answers[item.linkId].value.Code,
                extension: [
                  {
                    valueCode: answers[item.linkId].value.RxCUI,
                    url: ALLERGY_RXCUI,
                  },
                  {
                    valueCode: answers[item.linkId].value.CodeType,
                    url: ALLERGY_CODETYPE,
                  },
                ],
              },
            });
          } else {
            answer.push({
              valueCoding: {
                code: answers[item.linkId].value,
                display: answers[item.linkId].label,
                system: answers[item.linkId].system,
              },
            });
          }
        } else {
          answer.push({
            valueCoding: {
              code: answers[item.linkId],
            },
          });
        }
        // }
      }
      break;
    }
    case "boolean": {
      if (!answers) {
        answer.push({ valueBoolean: null });
      } else if (Array.isArray(answers) && answers.length > 0) {
        answers.forEach(element => {
          // if (element[item.linkId]) {
          answer.push({
            valueBoolean:
              element[item.linkId] === "false"
                ? false
                : element[item.linkId] === "true"
                ? true
                : null,
          });
          // }
        });
      } else {
        // if (answers[item.linkId]) {
        answer.push({
          valueBoolean:
            answers[item.linkId] === "false"
              ? false
              : answers[item.linkId] === "true"
              ? true
              : null,
        });
        // }
      }
      break;
    }
    case "decimal": {
      if (!answers) {
        answer.push({ valueDecimal: null });
      } else if (Array.isArray(answers) && answers.length > 0) {
        answers.forEach(element => {
          // if (element[item.linkId]) {
          answer.push({ valueDecimal: element[item.linkId] });
          // }
        });
      } else {
        // if (answers[item.linkId]) {
        answer.push({ valueDecimal: answers[item.linkId] });
        // }
      }
      break;
    }
    case "integer": {
      if (!answers) {
        answer.push({ valueInteger: null });
      } else if (Array.isArray(answers) && answers.length > 0) {
        answers.forEach(element => {
          // if (element[item.linkId]) {
          answer.push({ valueInteger: element[item.linkId] });
          // }
        });
      } else {
        // if (answers[item.linkId]) {
        answer.push({ valueInteger: answers[item.linkId] });
        // }
      }
      break;
    }
    default: {
      break;
    }
  }
  return answer;
};

export const createApptMetaResource = async (
  appointment,
  slotId,
  eventValues,
  entries,
  practitioner,
  organization
) => {
  let apptMeta = {};
  apptMeta["apptId"] = appointment.id;
  apptMeta["slotId"] = slotId;
  apptMeta["version"] = apptMeta.version ? apptMeta.version + 1 : 1;
  if (eventValues.videoVisit) {
    apptMeta["customUrl"] = false;
    if (
      organization.settings &&
      organization.settings.zoomEnabled &&
      organization.settings.zoomDefault &&
      practitioner.zoom &&
      practitioner.zoom.isAuthorized
    ) {
      let zoomMeetingDetails = {
        agenda: eventValues.description,
        start_time: appointment.start,
        duration: eventValues.duration,
        type: 2,
      };
      try {
        let zoomResponse = await createZoomMeeting(
          zoomMeetingDetails,
          practitioner._id,
          organization._id
        );
        if (zoomResponse.data == null || zoomResponse.data.error) {
          throw new Error(
            "Error occurred with Zoom scheduling. Please contact your Administrator or sign back into Zoom."
          );
        }
        apptMeta["videoUrl"] = zoomResponse.data.join_url;
        apptMeta["videoApptId"] = zoomResponse.data.id;
        apptMeta["videoVisit"] = true;
      } catch (error) {
        console.error(error);
        apptMeta[
          "videoUrl"
        ] = `https://virtualvisit.akutehealth.com/room/${appointment.id}`;
        apptMeta["videoVisit"] = true;
      }
    } else {
      apptMeta[
        "videoUrl"
      ] = `https://virtualvisit.akutehealth.com/room/${appointment.id}`;
      apptMeta["videoVisit"] = true;
    }
  } else {
    apptMeta["videoVisit"] = false;
    apptMeta["customUrl"] = false;
    apptMeta["videoUrl"] = "";
  }
  apptMeta["questions"] = [];
  if (Array.isArray(eventValues.questions) && eventValues.questions.length) {
    eventValues.questions.forEach((question, index) => {
      apptMeta.questions.push({
        text: question.text,
        answer: entries[`question-${index}`],
      });
    });
  }
  return apptMeta;
};

export const getPatientHintMembership = patient => {
  let membershipStatus = "active";
  if (Array.isArray(patient.extension) && patient.extension.length) {
    patient.extension.forEach(extension => {
      if (
        /hint.*patient/i.test(extension.url) &&
        Array.isArray(extension.extension)
      ) {
        extension.extension.forEach((extension, index) => {
          if (
            extension.url === "memberships" &&
            Array.isArray(extension.extension)
          ) {
            extension.extension.forEach(membershipProp => {
              if (membershipProp.url === "status") {
                membershipStatus = membershipProp.valueString;
              }
            });
          }
        });
      }
    });
  }
  return membershipStatus;
};

export const parseUserName = user => {
  return `${user.prefix ? `${user.prefix} ` : ""}${user.firstName} ${
    user.lastName
  }${user.suffix ? `, ${user.suffix}` : ""}`;
};

export const isObjEmpty = obj => {
  if (typeof obj === "object") {
    for (let i in obj) {
      return false;
    }
  }
  return true;
};
